<template>
  <section class="signup">
    <div class="row">
      <div class="col">
        <div class="intro">
          <div class="logo">
            <a href="/">
              <img src="/images/slack-logo.svg" alt="fe-tools" />
            </a>
          </div>
          <p>
            Welcome to FETOOLS a powerful app to store snippets, notes and mixins for your
            future projects.
          </p>
        </div>
      </div>
      <div class="col">
        <div class="signup-content">
          <form @submit.prevent class="signup-form">
            <strong class="form-title">Reset Password</strong>
            <label class="input-wrap">
              <input v-model.trim="resetForm.password" class="input" type="password" />
              <span class="label">Password</span>
            </label>
            <div class="forgot-pwd">
              <a href="/login">login</a>
            </div>
            <div class="error" v-if="errors.length">
              <div
                v-for="(error, index) in errors"
                :key="`error-${index}`"
                v-html="error"
              ></div>
            </div>
            <div class="submit-wrap">
              <input type="submit" @click="reset()" value="Reset password" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import { auth } from "../firebase";
export default {
  data() {
    return {
      errors: [],
      resetForm: {
        password: "",
      },
    };
  },
  methods: {
    reset() {
      let urlParams = new URLSearchParams(window.location.search);
      const mode = urlParams.get("mode");
      const actionCode = urlParams.get("oobCode");
      const newPassword = this.resetForm.password

      if(mode == 'resetPassword') {
        auth.confirmPasswordReset(actionCode, newPassword)
          .then(function() {
            window.location = '/'
          })
          .catch(error => { 
            this.errors.push(  error )
          })
      }
    }
  },
  created() {
    $(document).on("focus", ".input", function () {
      $(this).addClass("input-filled");
    });
    $(document).on("blur keyup", ".input", function () {
      if ($(this).val() != "") {
        $(this).addClass("input-filled");
      } else {
        $(this).removeClass("input-filled");
      }
    });
    $(".input").each(function () {
      if ($(this).val() != "") {
        $(this).addClass("input-filled");
      }
    });
  },
};
</script>
